import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";
import { color as xxlColor } from "@xxl/theme";
import type * as CSSType from "csstype";
import {
  MQ,
  interactionFeedback,
  typographyToCss,
} from "../../../../styles/helpers";
import spaces from "../../../../styles/theme/spaces";
import { containerMaxWidth } from "../../../../styles/xxl-theme";
import {
  GhostButton,
  GhostWhiteButton,
  PrimaryButtonInBanner,
  SecondaryButtonInBanner,
  TertiaryButtonInBanner,
  ghostButtonInteractionFeedbackCss,
  primaryButtonInteractionFeedbackCss,
  secondaryButtonInteractionFeedbackCss,
  tertiaryButtonInteractionFeedbackCss,
} from "../../../Common/XxlButton/XxlButton";
import type {
  BannerContentProps,
  BannerVariant,
  Padding,
  TextSize,
} from "./BannerContent.helper";
import Breakpoints from "../../../../styles/breakpoints.config";

const scalingFactor = 0.8;

const getFontScaling = (mobile: number, desktop: number) => {
  return `font-size: clamp(${mobile * scalingFactor}px, calc(${mobile * scalingFactor}px + (${desktop} - ${mobile * scalingFactor}) * (100vw - 1024px) / (${Breakpoints.bigDesktop} - 1024)), ${desktop}px);`;
};
type Alignment = { alignment: "flex-start" | "center" };

const bannerMaxWidth = containerMaxWidth;
const largeScreenBreakpoint = "laptop" as const;

export const MainContainer = styled.div<{
  containerHeight: BannerContentProps["height"];
  backgroundColor?: CSSType.Property.BackgroundColor;
}>(
  ({ backgroundColor, containerHeight: height }) => css`
    position: relative;
    background-color: ${backgroundColor ?? xxlColor.lightGray.hex};
    height: ${height.mobile};
    width: 100%;
    max-width: ${`${bannerMaxWidth}px`};

    ${MQ("smallTablet")} {
      height: ${height.smallTablet};
    }

    ${MQ(largeScreenBreakpoint)} {
      height: ${`${height.desktop}`};
    }
  `
);

export const SecondaryContainer = styled.div<{ hasOnlyOneLink?: boolean }>`
  position: absolute;
  top: auto;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${MQ(largeScreenBreakpoint)} {
    position: absolute;
    top: 0;
    bottom: auto;
    overflow: hidden;
  }

  ${({ hasOnlyOneLink = false }) =>
    hasOnlyOneLink &&
    css`
      ${interactionFeedback(css`
        & ${PrimaryButtonInBanner} {
          ${primaryButtonInteractionFeedbackCss}
        }
        & ${SecondaryButtonInBanner} {
          ${secondaryButtonInteractionFeedbackCss}
        }
        & ${TertiaryButtonInBanner} {
          ${tertiaryButtonInteractionFeedbackCss}
        }
        & ${GhostWhiteButton}, & ${GhostButton} {
          ${ghostButtonInteractionFeedbackCss}
        }
      `)}
    `}
`;

export const TextContainer = styled.div<{
  padding: Padding;
}>(
  ({ padding: { mobile, laptop } }) => css`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;
    max-width: ${`${bannerMaxWidth}px`};
    padding: ${mobile};

    ${MQ(largeScreenBreakpoint)} {
      padding: ${laptop};
    }
  `
);

export const DisclaimerInformationWrapper = styled.div<{
  variant: BannerVariant;
}>(
  ({ variant }) => css`
    display: flex;
    justify-content: flex-start;
    position: absolute;
    top: ${spaces.smallRegular};
    left: ${spaces.smallRegular};
    z-index: 1;

    ${MQ(largeScreenBreakpoint)} {
      top: ${variant === "full" || variant === "half"
        ? spaces.miniHuge
        : spaces.large};
      left: ${variant === "full" || variant === "half"
        ? spaces.miniHuge
        : spaces.large};
    }
  `
);

export const Timer = styled.div<Alignment>(
  ({ alignment }) => css`
    display: flex;
    justify-content: ${alignment};
    margin-bottom: ${alignment === "center" ? 0 : spaces.miniRegular};

    ${MQ(largeScreenBreakpoint)} {
      margin-bottom: ${alignment === "center" ? 0 : spaces.smallRegular};
    }
  `
);

export const Main = styled.div<Alignment>(
  ({ alignment }) => css`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: ${spaces.miniRegular};
    justify-content: ${alignment};
    align-items: ${alignment};
    text-align: ${alignment};

    ${MQ(largeScreenBreakpoint)} {
      gap: ${spaces.smallRegular};
    }
  `
);

export const Tagline = styled.p<{
  color: CSSType.Property.Color;
  isScalable?: boolean;
}>(
  ({ color, isScalable = false }) => css`
    margin: 0;
    color: ${color};

    ${typographyToCss({
      fontSize: 15,
      lineHeight: 1.2,
      letterSpacing: -0.2,
      fontFamily: "var(--font-family-medium)",
    })}

    ${isScalable
      ? `
      ${MQ("tablet")} {
      ${getFontScaling(15, 15)}
    }`
      : ""}
  `
);

const titleCss = ({
  color,
  size: { desktop, mobile },
  isScalable = false,
}: {
  color: CSSType.Property.Color;
  size: TextSize;
  isScalable?: boolean;
}) => css`
  margin: 0;
  color: ${color};

  ${typographyToCss({
    fontSize: mobile,
    fontFamily: "var(--font-family-bold)",
    lineHeight: 1,
  })}

  ${isScalable
    ? `
      ${MQ("tablet")} {
      ${getFontScaling(mobile, desktop)}
    }`
    : ""}

  word-break: break-word;
`;

export const HeroTitle = styled.h1<{
  color: CSSType.Property.Color;
  size: TextSize;
}>(({ color, size }) => titleCss({ color, size }));

export const Title = styled.h2<{
  color: CSSType.Property.Color;
  size: TextSize;
  isScalable?: boolean;
}>(({ color, size, isScalable = false }) =>
  titleCss({ color, size, isScalable })
);

export const Description = styled.p<{
  color: CSSType.Property.Color;
  size: TextSize;
  isScalable?: boolean;
}>(
  ({ color, size: { mobile, desktop }, isScalable = false }) => css`
    margin: 0;
    color: ${color};

    ${typographyToCss({
      fontSize: mobile,
      fontFamily: "var(--font-family-medium)",
      lineHeight: 1.2,
      letterSpacing: -0.15,
      laptop: {
        fontSize: desktop,
      },
    })}

    ${isScalable
      ? `
      ${MQ("tablet")} {
      ${getFontScaling(mobile, desktop)}
    }`
      : ""}
  `
);

const dynamicGapLaptop =
  "clamp(16px, calc(16px + (24 - 16) * ((100vw - 768px) / (1380 - 768))), 24px)";
const dynamicGapMobile =
  "clamp(12px, calc(12px + (24 - 12) * ((100vw - 325px) / (768 - 325))), 24px)";

export const ButtonContainer = styled.div<{
  alignment: {
    mobile: Alignment["alignment"];
    largeScreen: Alignment["alignment"];
  };
  backgroundColor: CSSType.Property.BackgroundColor;
  padding: Padding;
}>(
  ({ alignment, backgroundColor, padding }) => css`
    display: flex;
    background-color: ${backgroundColor};
    justify-content: ${alignment.mobile};
    flex-wrap: wrap;
    padding: ${padding.mobile};
    gap: ${dynamicGapMobile};

    ${GhostButton} {
      color: ${backgroundColor === xxlColor.webBlack.hex
        ? xxlColor.white.hex
        : xxlColor.webBlack.hex};
      ${interactionFeedback(ghostButtonInteractionFeedbackCss)}
    }

    ${MQ("tablet")} {
      background-color: transparent;
      gap: ${dynamicGapLaptop};
      padding: ${padding.laptop};
      margin-top: 0;
      justify-content: ${alignment.largeScreen};

      ${GhostButton} {
        color: ${xxlColor.webBlack.hex};
        ${interactionFeedback(ghostButtonInteractionFeedbackCss)}
      }
    }
  `
);

export const ImageAreaContainer = styled.div`
  position: relative;
  display: flex;
`;
